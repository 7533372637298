import { yupResolver } from '@hookform/resolvers'
import { StandardDialog } from '@ifca-root/react-component/src/components/Dialog/StandardDialog'
import SnackBarMsg from '@ifca-root/react-component/src/components/SnackBar/SnackBarMsg'
import {
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
} from '@material-ui/core'
import { Visibility, VisibilityOff } from '@material-ui/icons'
import {
  getRegistrationToken,
  setAccessToken,
} from '@hr-root/hr-employee-react/src/AccessToken'
import Logo from 'assets/images/logo.png'
import EonLogo from '@ifca-root/react-component/src/assets/images/EON_LOGO.png'
import SehsaLogo from '@ifca-root/react-component/src/assets/images/SEHSA_LOGO.svg'
import Background from 'assets/images/HR-Background.jpg'
import { AuthLayout } from 'components/AuthLayout/AuthLayout'
import {
  LoggedInUserProfileDocument,
  LoggedInUserProfileQuery,
  SoftwareCode,
  useLoginSsoLazyQuery,
} from '@hr-root/hr-employee-react/src/generated/graphql'
import React, { useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useHistory } from 'react-router'
import { LoginSchema } from 'yupValidation/yup'
import { encryptMessage } from 'containers/helper/Crypto/JsEncrypt'
import { CopyRight } from '@ifca-root/react-component/src/components/AuthLayout/CopyRight'
import { useLoginMutation } from '@hr-root/hr-employee-react/src/generated/graphql'
import { PublicClientApplication } from '@azure/msal-browser'
import {
  msalConfig,
  loginRequest,
} from '@hr-root/hr-employee-react/src/containers/Authentication/authConfig'

interface ILoginForm {
  email: string
  password: string
  showPassword: boolean
  rememberMe: boolean
  product: string
}
export const Login = () => {
  const rememberme: any = localStorage.getItem('rememberme')
  localStorage.clear()
  if (rememberme !== null) {
    localStorage.setItem('rememberme', rememberme)
  }
  let history = useHistory()

  const checksso = window?.location?.href?.split('/')[3]

  function LoginSuccess(user) {
    localStorage.setItem('loginInfo', JSON.stringify(user))

    let url = ''
    var prefix = ''
    switch (process.env.REACT_APP_API_URL) {
      case 'ifca-build':
        url = 'ifcahome.hrx.asia'
        prefix = 'https'
        break
      case 'prod-v5-build':
        url = 'hrms.hrx.asia'
        prefix = 'https'
        break
      case 'uat-v5-build':
        url = 'hrms-uat.hrx.asia'
        prefix = 'https'
        break
      case 'uat2-v5-build':
        url = 'hr-uat.hrx.asia'
        prefix = 'https'
        break
      case 'dev-v2-build':
        url = 'hrx-dev-employee.ifca.io'
        prefix = 'https'
        break
      case 'demo-build':
        url = 'hrms-demo.hrx.asia'
        prefix = 'https'
        break
      default:
        url = 'localhost:3010'
        prefix = 'http'
    }

    window.location.href = `${prefix}://${url}/home`
  }

  const [login, { loading }] = useLoginMutation({
    onError: error => {
      error.graphQLErrors.map(({ message }) => {
        console.log('ERROR', message)
        setMessage(message)
        setOpenAlert(true)
      })
    },
    update: (store, { data }) => {
      if (!data) {
        return null
      }

      store.writeQuery<LoggedInUserProfileQuery>({
        query: LoggedInUserProfileDocument,
        data: {
          __typename: 'Query',
          loggedInUserProfile: data.login.user as any,
        },
      })
    },
    onCompleted: data => {
      if (data && data.login.accessToken) {
        setAccessToken(data.login.accessToken)
      }
      if (rememberMe) {
        let rememberme = {
          username: getValues('email'),
          password: getValues('password'),
        }
        localStorage.setItem('rememberme', JSON.stringify(rememberme))
      } else {
        localStorage.removeItem('rememberme')
      }

      setTimeout(() => {
        LoginSuccess(data?.login?.user)
      }, 500)
    },
  })

  const [values, setValues] = React.useState(false)
  const [open, setOpen] = useState(false)
  const [openAlert, setOpenAlert] = useState(false)
  const [message, setMessage] = useState('')
  const [rememberMe, setRememberMe] = useState(
    rememberme !== null ? true : false
  )

  const handleCloseAlert = () => {
    setOpenAlert(false)
  }

  const handleCloseSnackBar = () => {
    setOpen(false)
    history.push('/profile')
  }
  const MESSAGE = 'Password Changed Successfully'

  const handleClickShowPassword = () => {
    setValues(!values)
  }

  const handleMouseDownPassword = event => {
    event.preventDefault()
  }

  const handleCheckRememberMe = (checked: boolean) => {
    setRememberMe(checked)
  }

  //useForm set DefaultValues, validationSchema
  const { handleSubmit, register, errors, control, getValues, reset } = useForm<
    ILoginForm
  >({
    defaultValues: {
      email: rememberme !== null ? JSON.parse(rememberme)?.username : '',
      password: rememberme !== null ? JSON.parse(rememberme)?.password : '',
    },
    resolver: yupResolver(LoginSchema),
  })

  const onSubmit = data => {
    login({
      variables: {
        loginId: data?.email,
        softwareCode: SoftwareCode.Hr,

        password: encryptMessage(data?.password),
      },
    })
  }

  const [
    loginSSOQuery,
    { data: data1, loading: loading1, error: error1 },
  ] = useLoginSsoLazyQuery({
    onCompleted: data => {
      if (data) {
        if (data && data.loginSSO.token) {
          setAccessToken(data.loginSSO.token)
        }
        LoginSuccess(data?.loginSSO?.user)
      }
    },
    onError: error => {
      console.log(error?.message)
      setMessage('Error : ' + error?.message)
      setOpenAlert(true)
    },
  })

  const submitSSOLogin = (token: string) => {
    loginSSOQuery({
      variables: {
        token: token,
        deviceToken: getRegistrationToken(),
      },
    })
  }

  const handleLogin = async () => {
    try {
      const msalInstance = new PublicClientApplication(msalConfig)
      const result = await msalInstance.loginPopup(loginRequest)
      if (result) {
        submitSSOLogin(result.accessToken)
      }
    } catch (error) {
      console.error('Authentication error:', error)
    }
  }

  return (
    <>
      <StandardDialog
        fullWidth={true}
        open={openAlert}
        onClose={handleCloseAlert}
        sections={{
          header: {
            title: 'Login Error',
          },
          body: () => <div>{message}</div>,
          footer: {
            actions: [
              {
                displayText: 'Ok',
                props: {
                  onClick: () => handleCloseAlert(),
                  variant: 'contained',
                  color: 'primary',
                },
              },
            ],
          },
        }}
      />
      <SnackBarMsg
        open={open}
        message={MESSAGE}
        onClose={handleCloseSnackBar}
      />

      <AuthLayout
        logo={
          ['prod-eon-build', 'uat-eon-build']?.includes(
            process.env.REACT_APP_API_URL
          )
            ? EonLogo
            : process.env.REACT_APP_API_URL == 'prod-sehsa-build'
            ? SehsaLogo
            : Logo
        }
        image={Background}
      >
        <span className="page-title">Login</span>
        <form onSubmit={handleSubmit(onSubmit)} id="submit-form">
          <div className="form-box">
            <Controller
              as={TextField}
              fullWidth
              helperText={errors?.email?.message}
              error={errors?.email ? true : false}
              autoComplete="off"
              name="email"
              label="Mobile No. / Email"
              control={control}
              ref={register}
            />
            <Grid container justifyContent="flex-start">
              <Controller
                as={TextField}
                fullWidth
                helperText={errors?.password?.message}
                error={errors?.password ? true : false}
                autoComplete="off"
                name="password"
                label="Password "
                type={values ? 'text' : 'password'}
                value={getValues('password')}
                //   onChange={handleChange('password')}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="Password"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {values ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                control={control}
                ref={register}
              />
            </Grid>

            <div className="other-auth">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={rememberMe}
                    onChange={e => handleCheckRememberMe(e.target.checked)}
                    color="primary"
                  />
                }
                ref={register}
                label="Remember Me"
              />
              <span
                className="forget-link"
                onClick={() => history.push('/forgot-password')}
              >
                Forgot Password?
              </span>
            </div>

            <Button
              type="submit"
              variant="contained"
              color="primary"
              className="login-btn"
              disabled={loading}
            >
              {!loading ? (
                'Login'
              ) : (
                <CircularProgress size={'1.5rem'} style={{ color: 'white' }} />
              )}
            </Button>
            {checksso == 'login-sso' ? (
              <Button
                onClick={handleLogin}
                variant="contained"
                color="primary"
                className="login-btn"
              >
                {' '}
                Login with Microsoft
              </Button>
            ) : null}
          </div>
        </form>
        <CopyRight />
      </AuthLayout>
    </>
  )
}
